import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import CtaPrimary from '../Buttons/primary-cta';

const { Title } = Typography;

const SmallCta = (props) => {
  const { sectionData } = props;

  return (
    <section className="cta">
      <div className="cta-small">
        <Title level={2} className="text-center">
          {sectionData.title}
        </Title>
        {sectionData.subtext && (
          <p className="text-center">{sectionData.subtext}</p>
        )}
        <CtaPrimary
          ctaTitle={sectionData.ctaInfo.ctaTitle}
          target={sectionData.ctaInfo.ctaTarget}
          className="false-center"
        />
      </div>
    </section>
  );
};

SmallCta.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
SmallCta.defaultProps = {
  sectionData: '',
};

export default SmallCta;
